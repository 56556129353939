.ModalContainer {
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    flex: 1;
    padding:20px;
  }

  .ModalWindow {
    display: flex;
    flex-direction: column;
    background-color:#191F28;
    border-radius: 15px;
    flex: 0.25;
  }

  .ModalText{
    flex:1 1;
    color:white;
    text-align: right;
    font-size: large;
    margin-right: 10px;
    white-space: nowrap;
  }

  .closeButton{
    display:flex;
    justify-content: center;
    align-items: center;
    height:20px;
    width: 20px;
    margin-left: auto;
   
  }
  .updButton{
    display:flex;
    justify-content: center;
    align-items: center;
    height:20px;
    width: 20px;
   
  }
  .ModalupButt {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    margin-right:20px;
    margin-left:20px;
  }
  
  .ModalInput{
    display: flex;
    flex:1;
    margin-left: 10px;
    border-radius: 30px;
   
    outline:none;
    text-align: center;
    border: 0;
    padding: 5px;
    
  }
  .Modalhead {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 40px;
    /* background-color: red; */
  }
  .Modalhead2 {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 20px;
    /* background-color: red; */
  }

  .ModalButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color:#0079C1;
    padding:10px 20px 10px;
    border-radius: 20px;
    border:0px;
    font-size: large;
    transition:background-color .5s, padding-left .5s,padding-right .5s;
  }
  
  .ModalButton:hover{
    padding:10px 30px 10px;
    background-color:rgb(2, 161, 253);
    transition: background-color .5s,padding-left .5s,padding-right .5s;
  }
  