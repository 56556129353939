
@font-face {
  font-family: 'DIN Pro';
  src: url('../fonts/DINPro.eot');
  src: local('DIN Pro'), local('DINPro'),
  url('../fonts/DINPro.eot?#iefix') format('embedded-opentype'),
  url('../fonts/DINPro.woff') format('woff'),
  url('../fonts/DINPro.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro Light';
  src: url('../fonts/DINPro-Light.eot');
  src: local('DIN Pro Light'), local('DINPro-Light'),
  url('../fonts/DINPro-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/DINPro-Light.woff') format('woff'),
  url('../fonts/DINPro-Light.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro Bold';
  src: url('../fonts/DINPro-Bold.eot');
  src: local('DIN Pro Bold'), local('DINPro-Bold'),
  url('../fonts/DINPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/DINPro-Bold.woff') format('woff'),
  url('../fonts/DINPro-Bold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

.pie_chart__legend_item_block {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 6px 12px 12px;
  border-bottom: 1.5px solid #555555;
  /* width: 100%; */
  user-select: none;
  cursor: default;
}

.round_marker {
  width: 12px;
  height: 12px;
  border-radius: 6px;
}

.pie_chart__legend_item_block .round_marker {
  margin-right: 10px;
}

.pie_chart__legend_item_block:first-child {
  border-top: 1.5px solid #555555;
}

.pie_chart__container {
  display: flex;
  flex: 1;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
}

.pie_chart__chart_container {
  display: flex;
  width: 35%;
  padding-left: 5%;
}

.pie_chart__legend_block__content {
  /* width: 100%; */
  padding-right: 5%;
  padding-left: 10%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pie_chart__legend_title {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 30px;
  flex: 2;
}

.pie_chart__legend_value {
  display: block;
  text-align: end;
  flex: 1;
  font-size: 30px;
  padding-right: 5px;
}

.group_bar_chart__wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* min-height: 100%; */
  max-height: 100%;
}

.chart_vertical_bar__wrapper {
  flex: 1;
  /* min-height:80%;
  height: 80%; */
  max-height: 80%;
  padding-left: 4%;
  padding-right: 4%;
}

.chart_horizontal_bar__wrapper {
  flex: 1;
  /* min-height: 80%;
  height: 80%; */
  max-height: 80%;
  padding-left: 4%;
  padding-right: 4%;
}

.full_height {
  max-height: 100%;
  flex: 1;
  padding-left: 4%;
  padding-right: 4%;
}
