body {
  height: 100vh;
  background-color: #191F28;
  color: white;
}

.container {
  /* background-color: #0E131A; */
  /* background-color: tomato; */
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column; 
  height: 100vh;
  min-width:100%;
  padding:3%;
  box-sizing: border-box;

}
.InputContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #191F28;
  /* background-color: white; */
  flex: 1;
  /* max-width: 100%;
  max-height: 100%; */
  min-width: 100%;
  /* min-height: 100%; */
  /* padding-top: 5%; */
  
  
}
.QrCont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  flex: 1;
}
.TextQr {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  flex: 1;
}

.HideButton{
  position: absolute;
  height: 50px;
  width:50px;
  top: 0px; 
  right: 0px;
}

.InputContainer2 {
  display: flex;
  flex-direction: column;
  
  flex: 1;
  /* padding-bottom:7%; */
  /* background-color: red; */
  /* min-height: 90%; */
}

.Title {
  display: flex;
  align-self: center;
  font-size:40px;
  font-weight: 600;
  padding-bottom: 20px;
  user-select: none;   
  text-align: center;
  /* background-color: green; */
}
.SubTitle {
  display: flex;
  align-self: center;
  font-size: 30px;
  color: white;
  font-weight: 400;
  padding-bottom: 20px;
  user-select: none;   
  text-align: center;
}

.button {
  background-color:#191F28;
  padding:20px 40px 20px;
  border-radius: 30px;
  border-color: #0079C1;
  border-width: 1px;
  border-style:solid;
  user-select: none; 
  transition: background-color .5s,padding-left .5s,padding-right .5s;
}

.button:hover{
  padding:20px 60px 20px;
  background-color: #0079C1;
  transition: background-color .5s,padding-left .5s,padding-right .5s;
}

.errText{
  color: red;
  font-size:large;
  font-weight: 600;
  padding-bottom: 40px;
  text-align: center;
}

